/******************************

INDEX:

    00 - Base - Typography
    01 - Base - Variables
    02 - Base - Mixins

    03 - Utilities - Helper
    04 - Utilities - Color

    10 - Components - Navigation
    11 - Components - Slider
    12 - Components - Button
    13 - Components - Banner
    14 - Components - Product
    15 - Components - Product Tab
    16 - Components - Brand
    17 - Components - Form
    18 - Components - Social Link
    19 - Components - Animation
    20 - Components - Breadcrumb
    21 - Components - Modal
    22 - Components - Instagram
    23 - Components - Service

    25 - Section - Header
    26 - Section - Footer

    29 - Pages - Blog
    30 - Pages - Shop
    31 - Pages - Single Product
    32 - Pages - About Us
    33 - Pages - Cart
    34 - Pages - Checkout
    35 - Pages - Compare
    36 - Pages - FAQ
    37 - Pages - Wishlist
    38 - Pages - My Account
    39 - Pages - Login | Register
    40 - Pages - Contact
    41 - Pages - 404

******************************/

/*

    Primary Color:     #a8741a;
    Text Color:        #151515;
    Border Color:      #e5e5e5;


    Font Family List:-
    
    font-family: 'Quicksand', sans-serif;
    font-family: 'Poppins', sans-serif;
    font-family: 'Titillium Web', sans-serif;
    font-family: 'Muli', sans-serif;
    additional-font_family: 'Freestyle Script';

*/

// Fonts
@import url('https://fonts.googleapis.com/css?family=Muli:400,400i,500,500i,600,600i,700,700i|Poppins:400,400i,500,500i,600,600i,700,700i|Quicksand:400,500,600,700|Titillium+Web:400,400i,600,600i,700,700i&display=swap');

//Base
@import 'base/variables';
@import 'base/mixins';
@import 'base/typography';

// Utilities
@import 'utilities/helper';
@import 'utilities/color';

// Components
@import 'components/navigation';
@import 'components/slider';
@import 'components/button';
@import 'components/service';
@import 'components/banner';
@import 'components/product';
@import 'components/product-tab';
@import 'components/brand';
@import 'components/form';
@import 'components/social-link';
@import 'components/animation';
@import 'components/breadcrumb';
@import 'components/modal';
@import 'components/instagram';
@import 'components/best-deals';
@import 'components/newsletter-with_testimonial';

// Section
@import 'section/header';
@import 'section/footer';

// Pages
@import 'pages/blog';
@import 'pages/shop';
@import 'pages/404';
@import 'pages/contact';
@import 'pages/faq';
@import 'pages/my-account';
@import 'pages/login-register';
@import 'pages/cart';
@import 'pages/wishlist';
@import 'pages/checkout';
@import 'pages/single-product';
@import 'pages/compare';
@import 'pages/about-us';
