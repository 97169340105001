/*----------------------------------------*/
/*  24 - Components - Best Deals
/*----------------------------------------*/
.best-deal_wrap{
    @include breakpoint(max-lg_device){
        padding-bottom: 25px;
    }
    .best-deal_slider{
        .best-deal_item{
            position: relative;
            &.best-deal-bg-01,
            &.best-deal-bg-02,
            &.best-deal-bg-03,
            &.best-deal-bg-04,
            &.best-deal-bg-05{
                background-image: url('../images/best-deal/bg/1-1.jpg');
                background-repeat: no-repeat;
                background-position: center center;
                background-size: cover;
                min-height: 470px;
                @include breakpoint(max-xl_device){
                    min-height: 350px;
                }
                @include breakpoint(max-lg_device){
                    min-height: 450px;
                }
                @include breakpoint(max-sm_device){
                    min-height: 320px;
                }
                @include breakpoint(max-xxs_device){
                    background-position: left;
                }
            }
            &.best-deal-bg-02{
                background-image: url('../images/best-deal/bg/1-2.jpg');
            }
            &.best-deal-bg-03{
                background-image: url('../images/best-deal/bg/1-3.jpg');
            }
            &.best-deal-bg-04{
                background-image: url('../images/best-deal/bg/1-4.jpg');
            }
            &.best-deal-bg-05{
                background-image: url('../images/best-deal/bg/1-5.jpg');
            }
            .best-deal_content{
                position: absolute;
                top: 50%;
                left: 10%;
                transform: translateY(-50%);
                @include breakpoint(max-xl_device){
                    left: 5%;
                }
                span{
                    &.product-discount{
                        font-size: 18px;
                        @include breakpoint(max-sm_device){
                            font-size: 16px;
                        }
                    }
                    &.product-offer{
                        display: block;
                        font-size: 18px;
                        line-height: 40px;
                        padding-bottom: 10px;
                        @include breakpoint(max-sm_device){
                            font-size: 16px;
                        }
                    }
                }
                > h3{
                    font-size: 36px;
                    line-height: 40px;
                    margin-bottom: 5px;
                    @include breakpoint(max-sm_device){
                        font-size: 25px;
                    }
                }
                .quicky-btn-ps_left{
                    padding-top: 15px;
                }
            }
        }
        &.best-deal_arrow{
            .tty-slick-text-btn{
                background-color: $white-color;
                color: $text-color;
                border-radius: 100%;
                width: 40px;
                height: 40px;
                line-height: 42px;
                font-size: 14px;
                @include breakpoint(max-sm_device){
                    display: none !important;
                }
                &:hover{
                    background-color: $text-color;
                    color: $white-color;
                }
            }
        }
    }
}

/* ---Testimonial Wrap--- */
.testimonial-wrap{
    .testimonial-slider{
        .testimonial-item{
            position: relative;
            &.testimonial-bg-01{
                background-image: url('../images/best-deal/bg/2-1.jpg');
                background-repeat: no-repeat;
                background-position: center center;
                background-size: cover;
                min-height: 470px;
                @include breakpoint(max-xl_device){
                    min-height: 350px;
                }
            }
            .testimonial-content{
                border: 2px solid #555555;
                width: 460px;
                margin: 0 auto;
                position: absolute;
                top: 29%;
                left: 50%;
                transform: translateX(-50%);
                @include breakpoint(max-sm_device){
                    width: 350px;
                    top: 18%;
                }
                @include breakpoint(max-xxs_device){
                    width: 280px;
                }
                &:before{
                    content: '\f1b2';
                    font-family: 'Material-Design-Iconic-Font';
                    position: absolute;
                    top: -20px;
                    font-size: 45px;
                    background: #f6efe0;
                    width: 35px;
                    height: 35px;
                    line-height: 35px;
                    left: -6px;
                }
                &:after{
                    content: "";
                    border-top: 2px solid transparent;
                    border-bottom: 2px solid #555555;
                    border-left: 2px solid transparent;
                    border-right: 2px solid #555555;
                    width: 37px;
                    height: 37px;
                    line-height: 30px;
                    position: absolute;
                    bottom: -20px;
                    left: 160px;
                    transform: skew(0deg, 45deg);
                    @include breakpoint(max-sm_device){
                        display: none;
                    }
                }
                p{
                    &.short-desc{
                        padding: 30px;
                        margin-bottom: -15px;
                        font-size: 16px;
                        @include breakpoint(max-sm_device){
                            padding: 20px;
                            font-size: 14px;
                        }
                    }
                }
                .user-info{
                    position: absolute;
                    bottom: -75px;
                    right: 115px;
                    @include breakpoint(max-sm_device){
                        right: auto;
                        left: 50%;
                        transform: translateX(-50%);

                    }
                    > h3{
                        &.user-name{
                            font-size: 18px;
                            line-height: 24px;
                            margin-bottom: 0;
                        }
                    }
                    > span{
                        &.user-occupation{
                            font-size: 15px;
                        }
                    }
                }
            }
        }
    }
}
