/*----------------------------------------*/
/*  12 - Components - Button
/*----------------------------------------*/
/* ---Quicky's Button Position--- */
.quicky-btn-ps_center {
	display: flex;
	justify-content: center;
	&.quicky-btn-ps_left {
		justify-content: flex-start;
	}
	&.quicky-btn-ps_right {
		justify-content: flex-end;
	}
}

.quicky-btn{
	position: relative;
	line-height: 26px;
	&.horizontal-line_ltr{
		@include breakpoint(max-xs_device){
			font-size: 14px;
		}
		&:before{
			background-color: $night-rider_color;
			content: "";
			position: absolute;
			width: 100px;
			height: 2px;
			left: 185px;
			top: 50%;
			transform: translate(-50%);
			@include breakpoint(max-xs_device){
				left: 135px;
				width: 50px;
			}
		}
		&.horizontal-line_rtl{
			&:before{
				left: auto;
				right: 85px;
			}
		}
	}
	&.vertical-line{
		&:before{
			background-color: $night-rider_color;
			content: "";
			position: absolute;
			width: 100%;
			height: 1px;
			bottom: 0;
		}
	}
}

/* ---Quicky's Button Style Two--- */
.quicky-btn-2 {
	box-shadow: 0px 8px 15px rgba(0, 0, 0, 0);
	background-color: $text-color;
    color: $white-color !important;
	width: 175px;
	height: 45px;
	line-height: 45px;
	display: block;
	text-align: center;
	text-transform: uppercase;
	font-weight: 400;
    @extend %basetransition;
    border-radius: 25px;
	font-size: 16px;
	&.transparent-btn{
		background-color: transparent;
	}
	// Fullwidth
	&.quicky-btn_fullwidth{
		width: 100%;
	}
	&.quicky-btn_sm {
		width: 100%;
		height: 40px;
		line-height: 40px;
		font-size: 13px;
	}
	// Color
	&.black-color{
		border: 1px solid $black-color;
		font-size: 14px;
	}
	// Button Style
	&.square-btn{
		border-radius: 0;
	}
}