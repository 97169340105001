/*----------------------------------------*/
/*  04 - Utilities - Color
/*----------------------------------------*/
/* ---Background Color--- */
.bg-smoke_color{
    background-color: $white-smoke_color;
}
.bg-white_color{
    background-color: $white-color;
}
.bg-buttery-white{
    background-color: $buttery-white;
}
.bg-wisp-pink{
    background-color: $wisp-pink_color;
}
.bg-snow{
    background-color: $snow-color;
}
.bg-clear_day{
    background-color: #ddeee9;
}
.bg-black{
    background-color: #000;
}

@for $i from 1 through length($colorList) {
    .template-color-#{$i} {
        a{
            color: $text-color;
            &:hover{
                color: nth($colorList, $i);
            }
        }
        [class*="active"]{
            > a{
                color: nth($colorList, $i);
            }
        }

        .loading {
            .middle {
                .loader {
                    border: 4px solid nth($colorList, $i);
                }
        
                .loader-inner {
                    background-color: nth($colorList, $i);
                }
            }
        }

        .quicky-btn-2{
            &:hover{
                background-color: nth($colorList, $i);
            }
        }

        .quicky-post_btn {
            &:hover {
                background-color: nth($colorList, $i) !important;
                color: $white-color;
            }
        }

        .quicky-group_btn {
            > ul {
                > li {
                    > a {
                        &.add-to_cart {
                            &:hover {
                                background-color: nth($colorList, $i) !important;
                                color: $white-color !important;
                            }
                        }
                        &:hover {
                            border-color: nth($colorList, $i) !important;
                        }
                    }
                }
            }
        }

        .login_btn,
        .register_btn {
            &:hover {
                background: nth($colorList, $i);
            }
        }

        .quicky-cart_btn {
            a {
                &:hover {
                    background: nth($colorList, $i);
                    color: $white-color !important;
                }
            }
        }

        .slick-dots {
            li {
                &:hover {
                    background-color: nth($colorList, $i);
                    border-color: nth($colorList, $i);
                }
            }
        }

        .slider-navigation{
            button{
                &:hover{
                    background-color: unset;
                    color: nth($colorList, $i) !important;
                }
            }
        }

        .arrow-style-2{
            .slick-arrow{
                background-color: #ddeee9;
                &:hover{
                    background-color: nth($colorList, $i);
                }
            }
        }

        .social-link-4 {
            > ul {
                > li {
                    &:hover{
                        > a{
                            background-color: nth($colorList, $i);
                            border: 1px solid nth($colorList, $i);
                            color: $white-color;
                        }
                    }
                }
            }
        }

        .related-post_content {
            > span {
                > strong {
                    color: nth($colorList, $i);
                }
            }
        }

        .quicky-paginatoin-area {
            .quicky-pagination-box {
                li {
                    &.active {
                        > a {
                            background-color: nth($colorList, $i);
                            color: $white-color;
                        }
                    }
                    &:hover {
                        > a {
                            background-color: nth($colorList, $i);
                            color: $white-color;
                        }
                    }
                }
            }
        }

        .quicky-tag-line {
            a {
                &:hover {
                    color: nth($colorList, $i);
                }
            }
        }

        .search-btn {
            &:hover {
                color: nth($colorList, $i);
            }
        }

        .reply-btn {
            a {
                color: nth($colorList, $i);
                border: 1px solid nth($colorList, $i);
                &:hover {
                    color: $white-color !important;
                    background-color: nth($colorList, $i);
                }
            }
        }

        .qty-btn_area {
            > ul {
                li {
                    > a {
                        &:hover{
                            background-color: nth($colorList, $i);
                            border-color: nth($colorList, $i);
                            color: $white_color;
                        }
                    }
                }
            }
        }

        .qty-cart_btn{
            background-color: $text-color;
            border-color: $text-color;
            color: $white-color;
            &:hover{
                background-color: nth($colorList, $i);
                border-color: nth($colorList, $i);
            }
        }

        .quicky-blog-tags {
			li {
				a {
					border: 1px solid $border-color;
					&:hover {
						color: $white-color;
						background-color: nth($colorList, $i);
						border-color: nth($colorList, $i);
					}
				}
			}
		}

        .banner-area-5{
            .banner-item{
                .banner-content{
                    > span{
                        color: nth($colorList, $i);
                    }
                }
            }
        }
        
        .form-group{
            .required {
            	color: nth($colorList, $i);
            }
        }

        .contact-form_btn {
            &:hover {
                background-color: nth($colorList, $i) !important;
                color: $white-color;
                border-color: nth($colorList, $i) !important;
            }
        }

        .about-us_btn {
            background: nth($colorList, $i);
        }
        
        .quicky-product-stock-status {
            span {
                &.in-stock,
                &.out-stock {
                    color: nth($colorList, $i);
                }
            }
        }

        .coupon,
        .coupon2 {
            input {
                &.button {
                    &:hover {
                        background: nth($colorList, $i);
                    }
                }
            }
        }

        .cart-page-total {
            a {
                &:hover {
                    background: nth($colorList, $i);
                    border-color: nth($colorList, $i);
                    color: $white-color !important;
                }
            }
        }

        .coupon-accordion {
            h3 {
                border-top: 3px solid nth($colorList, $i);
                &:before {
                    color: nth($colorList, $i);
                }
            }
            span {
                &:hover {
                    color: nth($colorList, $i);
                }
            }
            .coupon-content {
                .coupon-info {
                    p {
                        &.form-row {
                            input[type="submit"] {
                                &:hover {
                                    background: nth($colorList, $i);
                                }
                            }
                        }
                        &.checkout-coupon {
                            input[type="submit"] {
                                background: nth($colorList, $i);
                            }
                        }
                        &.lost-password {
                            a {
                                &:hover {
                                    color: nth($colorList, $i);
                                }
                            }
                        }
                    }
                }
            }
        }

        .coupon-checkout-content {
            .coupon-info {
                .checkout-coupon {
                    input[type="submit"] {
                        &:hover {
                            background: nth($colorList, $i);
                        }
                    }
                }
            }
        }

        .country-select,
        &.checkout-form-list {
            label {
                span {
                    &.required {
                        color: nth($colorList, $i);
                    }
                }
            }
        }

        .panel-title {
			a {
				&:hover {
					color: nth($colorList, $i) !important;
				}
			}
        }
        
        .order-button-payment {
            input {
                &:hover {
                    background: nth($colorList, $i);
                }
            }
        }

        .compare-product-name {
            a {
                &:hover {
                    color: nth($colorList, $i);
                }
            }
        }

        .modal-body{
            button{
                span{
                    @extend %basetransition;
                    &:hover{
                        color: nth($colorList, $i);
                    }
                }
            }
        }

        .btn-close{
            &:hover{
                color: nth($colorList, $i) !important;
            }
        }

        .offcanvas-search{
            button{
                &.search_btn{
                    &:hover{
                        > i{
                            color: nth($colorList, $i);
                        }
                    }
                }
            }
        }

        .main-nav{
            > ul{
                > li{
                    &:hover{
                        > a{
                            color: nth($colorList, $i);
                        }
                    }
                }
            }
        }

        .search-button{
            &:hover{
                color: nth($colorList, $i);
            }
        }

        .tty-slick-text-btn{
            &:hover{
                background-color: nth($colorList, $i);
                color: $white-color !important;
            }
        }

        .minicart-wrap{
            > a{
                &.minicart-btn{
                    .minicart-count_area{
                        > span{
                            &.item-count{
                                background-color: nth($colorList, $i);
                                color: $white-color;
                            }
                        }
                    }
                }
            }
        }

        .offcanvas-minicart_wrapper{
            .minicart-list {
				> li {
					&.minicart-product {
						.product-item_img {
							&:hover{
								border-color: nth($colorList, $i) !important;
							}
						}
					}
				}
			}
        }

        .megamenu-holder {
            > ul {
                > li {
                    > span {
                        &.megamenu-title {
                            &:before{
                                content: "";
                                background-color: nth($colorList, $i);
                            }
                        }
                    }
                }
            }
        }

        .product-view-mode {
			> a {
				&.active {
					color: nth($colorList, $i);
				}
			}
        }
        
        .ui-slider-range {
            background: nth($colorList, $i);
        }
        .ui-slider-handle {
            border: 4px solid nth($colorList, $i);
            &:hover {
                background-color: nth($colorList, $i);
            }
        }
        .filter-btn {
            &:hover {
                background-color: nth($colorList, $i);
                border-color: nth($colorList, $i);
                color: $white-color;
            }
        }

        .sidebar-categories_menu {
            ul {
                li {
                    &:hover {
                        > a {
                            color: nth($colorList, $i);
                            > i {
                                color: nth($colorList, $i);
                            }
                        }
                    }
                }
            }
        }

        .product-tab{
            > ul{
                &.product-menu{
                    > li{
                        > a{
                            &.active{
                                color: nth($colorList, $i);
                            }
                        }
                    }
                }
            }
        }

        .sp-img_slider-nav {
            .slick-slide{
                &.slick-current{
                    img{
                        border-color: nth($colorList, $i);
                    }
                }
            }
        }

        .quicky-paginatoin-area {
            .quicky-pagination-box {
                &.primary-color{
                    border: 2px solid nth($colorList, $i);
                    > li{
                        &:last-child {
                            > a {
                                border-right: 0;
                            }
                        }
                        &.active{
                            > a{
                                color: nth($colorList, $i);
                            }
                        }
                        > a{
                            border-right: 1px solid nth($colorList, $i);
                            &:hover{
                                color: nth($colorList, $i) !important;
                            }
                        }
                    }
                }
            }
        }

        .add-actions {
            > ul {
                > li {
                    > a {
                        &:hover {
                            color: nth($colorList, $i) !important;
                        }
                    }
                }
            }
        }

        .footer-widgets {
			> ul {
				> li {
                    > a{
						@extend %basetransition;
						padding-left: 0;
					}
					&:hover{
						> a{
							color: nth($colorList, $i);
							padding-left: 10px;
						}
					}
				}
			}
        }
        
        .quicky-social_link {
            > ul {
                > li {
                    > a {
                        &:hover {
                            background-color: nth($colorList, $i);
                            color: $white-color !important;
                        }
                    }
                }
            }
        }

        .account-page-area {
            .myaccount-tab-trigger {
                li {
                    a {
                        &.active {
                            background: nth($colorList, $i);
                            color: $white-color;
                            &:hover {
                                color: $white-color !important;
                            }
                        }
                        &:hover {
                            color: nth($colorList, $i);
                        }
                    }
                }
            }
        }

        .error404-area {
            .search-error-wrapper {
                h1 {
                    color: nth($colorList, $i);
                }
                .error-search_btn {
                    &:hover {
                        color: nth($colorList, $i);
                    }
                }
                .error-btn {
                    &:hover {
                        background: nth($colorList, $i);
                        color: $white-color;
                    }
                }
            }
        }
        .frequently-accordion {
            .actives {
                border: 1px solid nth($colorList, $i);
            }
            .card-header {
                a {
                    &:hover {
                        color: nth($colorList, $i) !important;
                    }
                }
            }
        }

        .quicky-tags_list {
            li {
                a {
                    &:hover {
                        color: $white-color !important;
                        background-color: nth($colorList, $i);
                        border-color: nth($colorList, $i);
                    }
                }
            }
        }

        .overview-content {
            > h2 {
                &:after {
                    background: nth($colorList, $i);
                }
                span {
                    color: nth($colorList, $i);
                }
            }
        }
        
        .count-title {
			h2 {
				color: nth($colorList, $i);
			}
		}

        .scroll-to-top {
            &:hover {
                background-color: nth($colorList, $i);
            }
        }

    }
}