//--- All Variables - Related Stuff Included In This Area ---↓
// Color
$primary-color:     #a8741a;
$primary-color-2:   #cf869a;
$primary-color-3:   #7f1519;
$primary-color-4:   #ff0000;
$primary-color-5:   #fb7785;
$primary-color-6:   #B03060;
$primary-color-7:   #6a0dad;
$primary-color-8:   #ffa500;
$primary-color-9:   #8e4585;
$primary-color-10:  #65000b;
$text-color:        #151515;
$heading-color:     #202020;
$border-color:      #e5e5e5;
$border-color-2:    #c4c4c4;
$buttery-white:     #ddeee9;
$wisp-pink_color :  #f3ebe9;

// Additional Color Variable
$white-color:       #ffffff;
$white-smoke_color: #f5f5f5;
$silver-color:      #bbbbbb;
$night-rider_color: #2f2f2f;
$snow-color:        #fff7f5;
$gamboge-color:     #DC9B0E;
$zircon-color:      #DAE5E7;

// Additional Font Family
$additional-font_family: 'Freestyle Script' !important;
$quicksand-font: 'Quicksand', sans-serif;
$poppins-font: 'Poppins', sans-serif;
$titillium-font: 'Titillium Web', sans-serif;
$muli-font: 'Muli', sans-serif;

// Slider Color Swatches
$burnt-orange_color:#ff832b;
$raw-umber_color:   #824900;
$black-color:       #000000;
$brown-color:       #a6311f;
$red-color:         #ff0000;

//----Social Link Color Variables ----↓
$facebook-color:    #3b579d;
$twitter-color:     #3acaff;
$google-plus-color: #dd4b39;
$linkedin-color:    #0097bd;
$pinterest-color:   #cb2027;
$instagram-color:   #833ab4;
$skype-color:       #00aff0;
$share-color:       #fe6d4c;
$youtube-color:     #d72525;

// Template Color Variation ----↓
$color-1: $primary-color;
$color-2: $primary-color-2;
$color-3: $primary-color-3;
$color-4: $primary-color-4;
$color-5: $primary-color-5;
$color-6: $primary-color-6;
$color-7: $primary-color-7;
$color-8: $primary-color-8;
$color-9: $primary-color-9;
$color-10: $primary-color-10;
$colorList: $color-1, $color-2, $color-3, $color-4, $color-5, $color-6, $color-7, $color-8, $color-9, $color-10;
