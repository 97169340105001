/*----------------------------------------*/
/*  20 - Components - Breadcrumb
/*----------------------------------------*/
.breadcrumb-area {
	background-image: url("../images/breadcrumb/1.jpg");
	background-repeat: no-repeat;
	background-position: center center;
	background-size: cover;
	min-height: 466px;
	position: relative;
	@include breakpoint(max-md_device){
		min-height: 250px;
	}
	.breadcrumb-content {
		@include absCenter;
		text-align: center;
		@include breakpoint (max-xs_device) {
			width: 100%;
		}
		> h2 {
			text-transform: uppercase;
			text-align: center;
			font-size: 36px;
			margin-bottom: 0;
			padding-bottom: 20px;
			@include breakpoint (max-lg_device) {
				font-size: 20px;
			}
		}
		ul {
			> li {
				display: inline-block;
				padding-left: 20px;
				position: relative;
				&:first-child {
					padding-left: 0;
				}
				&:before {
					content: "\f2ee";
					font-family: 'Material-Design-Iconic-Font';
					position: absolute;
					font-size: 10px;
					font-weight: 600;
					right: -15px;
					top: 50%;
					transform: translateY(-50%);
				}
				&:last-child {
					&:before {
						display: none;
					}
				}
			}
		}
	}
	&.breadcrumb-area-2{
		background-image: url("../images/breadcrumb/2.jpg");
		background-position: left;
		@include breakpoint(max-md_device){
			background-position: center;
		}
	}
}
