/*----------------------------------------*/
/*  15 - Components - Product Tab
/*----------------------------------------*/
.product-tab_area {
	.short-desc {
		margin: 0 auto;
		text-align: center;
		> p {
			margin-bottom: 0;
			padding-bottom: 15px;
		}
	}
	.product-tab {
		display: flex;
		justify-content: space-between;
		margin-bottom: 30px;
		padding-bottom: 25px;
		@include breakpoint (max-sm_device) {
			display: block;
		}
		.quicky-tab_title {
			@include breakpoint (max-sm_device) {
				padding-bottom: 20px;
			}
			> h4 {
				text-transform: uppercase;
				margin-bottom: 0;
				@include breakpoint (max-md_device) {
					font-size: 20px;
				}
			}
		}
		> ul {
			&.product-menu {
				@include breakpoint(max-xxs_device){
					display: block;
					width: 100%;
					text-align: center;
				}
				> li {
					> a {
						text-transform: uppercase;
						padding: 0 30px;
						position: relative;
						display: block;
						@include breakpoint (max-md_device) {
							padding: 0 15px;
							font-size: 14px;
						}
						@include breakpoint(max-xxs_device){
							padding: 0 0 10px 0;
						}
						
						&:after {
							background-color: $silver-color;
							content: "";
							position: absolute;
							top: 50%;
							right: -9px;
							height: 2px;
							width: 18px;
							@include breakpoint (max-md_device) {
								width: 10px;
								right: -5px;
							}
							@include breakpoint (max-xs_device) {
								display: none;
							}
						}
					}
					&:last-child {
						> a {
							padding-right: 0;
							@include breakpoint(max-xxs_device){
								padding: 0;
							}
							&:after {
								display: none;
							}
						}
					}
				}
			}
		}
	}
}
